/* eslint-disable */
import { StatusCodes } from "http-status-codes";
import { _copyObject } from "@/entities/funzioni-comuni";

const worklogModule = {
  namespaced: true,
  state: () => ({
    elenco: [],
    elencoPendente: [],
    elencoFiltrato: [],
    elencoFiltratoPendente: [],
    attivo: null,
    savingWorkLog: false,
    modificando: {
      item: null,
      elencoAttivita: [],
      elencoClienti: [],
      elencoWorker: [],
    },
    modificandoTemp: null,
    workerDefault: {},
    errore: "",
  }),
  mutations: {
    setModificando(state, modificando) {
      // console.log("mod", modificando);
      state.modificando = modificando;
    },
    setModificandoTemp(state, modificandoTemp) {
      state.modificandoTemp = modificandoTemp;
    },

    setElencoFiltrato(state, id) {
      state.elencoFiltrato = [...state.elenco];
      // console.log("state.elenco", state.elenco);
      if (id != "-1") {
        state.elencoFiltrato = [
          ...state.elencoFiltrato.filter(
            (wl) => wl.worker.id == id && wl.when != null
          ),
        ];
      }
    },

    setErrore(state, err) {
      state.errore = err;
    },

    setElencoFiltratoPendente(state, id) {
      state.elencoFiltratoPendente = [...state.elencoPendente];
      // console.log("state.elenco2", state.elencoPendente);
      if (id != "-1" && id != "-2") {
        state.elencoFiltratoPendente = [
          ...state.elencoFiltratoPendente.filter(
            (wl) => wl.worker.id == id && wl.when == null
          ),
        ];
      }
    },

    setElenco(state, atts) {
      // Log.debug('clienti/setElenco', clienti.length)
      state.elenco = atts;
    },

    setElencoPendente(state, atts) {
      // Log.debug('clienti/setElenco', clienti.length)
      state.elencoPendente = atts;
      // console.log("elenco pendente", state.elencoPendente);
    },
    setAttivo(state, cli) {
      state.attivo = _copyObject(cli);
    },
    resetAttivo(state) {
      state.attivo = null;
    },
    aggiungi(state, wl) {
      state.elenco = [...state.elenco, wl];
      // .sort((a, b) =>
      //   a.surnameCompany.toLowerCase() > b.surnameCompany.toLowerCase() ? 1 : -1
      // );
    },
    elimina(state, wl) {
      state.elenco = [...state.elenco].filter((wwl) => wwl.id !== wl.id);
    },
    aggiorna(state, wl) {
      state.elenco = [
        ...state.elenco.map((wwl) =>
          wwl.id === wl.id ? _copyObject(wl) : wwl
        ),
      ];
    },
    setSavingWorkLog(state, val) {
      // console.log("imposto savingAtt", val);
      state.savingWorkLog = val;
    },
  },
  actions: {
    setModificando({ commit }, modificando) {
      commit("setModificando", modificando);
    },

    setModificandoTemp({ commit }, modificandoTemp) {
      commit("setModificandoTemp", modificandoTemp);
    },

    setElencoFiltrato({ commit }, id) {
      commit("setElencoFiltrato", id);
    },

    setElencoFiltratoPendente({ commit }, id) {
      commit("setElencoFiltratoPendente", id);
    },

    async recuperaElenco({ commit, rootState }, filtroDate) {
      try {
        // console.log("filtrodate", filtroDate);
        let res = await this._vm.$api.worklog.fetchWorkLogs(filtroDate);
        if (res.status !== StatusCodes.OK) {
          throw new Error(
            "Errore server durante il recupero dell'elenco worklog"
          );
        }
        let wls = [...res.data];
        commit("setElenco", wls);
        res = await this._vm.$api.worklog.fetchWorkLogsScheduled(filtroDate);
        if (res.status !== StatusCodes.OK) {
          throw new Error(
            "Errore server durante il recupero dell'elenco pendenti worklog"
          );
        }
        wls = [...res.data];
        commit("setElencoPendente", wls);
        return true;
      } catch (error) {
        commit("setErrore", error.message);
        console.error(error);
        return false;
      }
    },

    async salvaWorkLog({ commit, state }, { wl, repValue }) {
      // console.log("salvaWorklog", wl);
      delete wl.worker.nomeCognome;
      delete wl.worker.active;

      // Rimuovo alcune prop
      ["customer", "activity", "worker"].forEach((e) => {
        delete wl[e].editing;
        delete wl[e].label;
      });

      commit("setSavingWorkLog", true);
      // console.log("x", { wl, repValue });
      try {
        let { price, quantity, expenses } = wl.activity;
        let { ...wlCopy } = wl;
        if (
          price !== undefined &&
          quantity !== undefined &&
          expenses !== undefined
        ) {
          wlCopy.activity = {
            ...wlCopy.activity,
            price,
            quantity,
            expenses,
          };
        }
        // console.log("dati", { price, quantity, expenses });
        // console.log("salverei: ", { wl, });
        let res;
        // console.log("wlCopy", wlCopy);
        if (wlCopy.id == -1) {
          res = await this._vm.$api.worklog.saveWorkLog(wlCopy, repValue);
        } else {
          res = await this._vm.$api.worklog.updateWorkLog(wlCopy, repValue);
        }

        if (res.status !== StatusCodes.OK) {
          commit("setSavingWorkLog", false);
          throw new Error("Errore server durante l'aggiunta del worklog");
        }
        wlCopy = res.data;
        let trovato = state.elenco.find((it) => it.id === wlCopy.id);
        if (_.isNil(trovato)) {
          // Log.debug("clienti - aggiungo", cli);
          commit("aggiungi", wlCopy);
        } else {
          // Log.debug("clienti - aggiorno", cli);
          commit("aggiorna", wlCopy);
        }
      } catch (error) {
        // console.log("salvaWorkLog error", error);
        commit("setErrore", error);
        commit("setSavingWorkLog", false);
        return false;
      }
      commit("setSavingWorkLog", false);
      return true;
    },

    async eliminaWorkLog({ commit, rootState }, wl) {
      try {
        const res = await this._vm.$api.worklog.deleteWorkLog(wl);
        commit("elimina", wl);
      } catch (error) {
        commit("setErrore", error.message);
        // console.log("error", error.message);
        return false;
      }
      return true;
    },
    setAttivo({ commit }, wl) {
      commit("setAttivo", wl);
    },
    resetAttivo({ commit }) {
      commit("resetAttivo");
    },
  },
  getters: {
    getWorklogByAtt: (state) => state.elenco.filter(),
  },
};

export default worklogModule;
