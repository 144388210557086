import _ from "lodash";

export const AlertType = Object.freeze({
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
});

export function Alert(alert) {
  const defaults = {
    show: true,
    type: AlertType.ERROR,
    msg: "Si è verificato un errore",
    duration: null,
    class: undefined,
    persistent: false,
  };
  if (_.isNil(alert.msg)) {
    throw Error("parametro richiesto: msg");
  }
  return {
    ...defaults,
    ...alert,
  };
}
