import {
  format,
  isBefore,
  isAfter,
  differenceInDays,
  startOfDay,
} from "date-fns";
import DateDefaults from "@/entities/date-defaults.js";

export const dateMinValue = Object.freeze(new Date("2023-01-01"));
export const dateMaxValue = Object.freeze(new Date("9999-12-31"));

export function formatDateToTypeISO_8601(date) {
  return format(date, DateDefaults.formats.ISO_8601);
}
export function formatDateToTypeLog(date) {
  return format(date, DateDefaults.formats.LOG);
}
export function formatDateToTypeClient(date) {
  return format(date, DateDefaults.formats.CLIENT);
}
export function formatDateToTypeOnlyDate(date) {
  return format(date, DateDefaults.formats.ONLY_DATE);
}
export function formatDateToTypeOnlyDay(date) {
  return format(date, DateDefaults.formats.ONLY_DAY);
}
export function formatDateToTypeOnlyDayAndMonth(date) {
  return format(date, DateDefaults.formats.ONLY_DAY_AND_MONTH);
}
export function formatDateToTypeTime(date) {
  return format(date, DateDefaults.formats.ONLY_HOUR);
}
export function formatDateToTypeTimeNoSeconds(date) {
  return format(date, DateDefaults.formats.ONLY_HOUR_AND_MINUTE);
}
export function formatDateToNumberMonthYear(date) {
  return format(date, DateDefaults.formats.NUMBER_MONTH_YEAR);
}

export function formatDateToNameAndNumberDay(date, shortName) {
  return `${date.toLocaleDateString("it-IT", {
    day: "2-digit",
    weekday: shortName ? "short" : "long",
  })}`;
}
export function formatDateToNumberAndNameDay(date, shortName) {
  return `${date.toLocaleDateString("it-IT", {
    day: "2-digit",
  })} ${date.toLocaleDateString("it-IT", {
    weekday: shortName ? "short" : "long",
  })}
  `;
}
export function formatDateToNameAndNumberDayAndMonth(date, shortName) {
  return date.toLocaleDateString("it-IT", {
    weekday: shortName ? "short" : "long",
    day: "2-digit",
    month: "long",
  });
}
export function formatDateToNumberAndMonthAndNameDay(date, shortName) {
  return `${date.toLocaleDateString("it-IT", {
    day: "2-digit",
    month: "2-digit",
  })} ${date.toLocaleDateString("it-IT", {
    weekday: shortName ? "short" : "long",
  })}`;
}

export function isDateBetweenValidRange(date) {
  return date
    ? !isBefore(date, this.dateMinValue) && !isAfter(date, this.dateMaxValue)
    : false;
}
export function getDateMinTypeClient() {
  return this.formatDateToTypeClient(this.dateMinValue);
}
export function getDateMinTypeOnlyDate() {
  return this.formatDateToTypeOnlyDate(this.dateMinValue);
}
export function getDateMaxTypeClient() {
  return this.formatDateToTypeClient(this.dateMaxValue);
}
export function getDateMaxTypeOnlyDate() {
  return this.formatDateToTypeOnlyDate(this.dateMaxValue);
}

export function diffInDaysFromNow(date) {
  /*
    rif: https://date-fns.org/v2.29.3/docs/differenceInDays

    dateLeft	Date | Number	 the later date
    dateRight	Date | Number	 the earlier date

    Se non è scaduto, la giornata di oggi è prima della scadenza
  */
  return differenceInDays(startOfDay(Date.now()), date);
}
