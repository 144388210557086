const GlobalHelpers = {
  /* eslint-disable-next-line no-unused-vars */
  install(Vue, options) {
    Vue.prototype.formatEuro = (num) =>
      new Intl.NumberFormat("it-IT", {
        style: "currency",
        currency: "EUR",
      }).format(num);
  },
};

export default GlobalHelpers;
