<template>
  <div id="loader">
    <div>
      <img
        src="@/assets/logo_progettobaseBianco.png"
        alt="worklog"
        class="logo"
      />
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";

const NAME = "LoaderMida";

export default {
  name: NAME,
  props: ["showLoader"],
  data() {
    return {
      visible: false,
    };
  },

  computed: {
    loader() {
      return document.getElementById("loader");
    },
  },
  created() {
    bus.$on("showLoader", this.doShow);
    bus.$on("hideLoader", this.doHide);
  },
  mounted() {
    if (this.showLoader) {
      this.doShow();
    }
  },
  beforeUpdate() {
    this.doHide(false);
  },

  beforeDestroy() {
    bus.$off("showLoader", this.doShow);
    bus.$off("hideLoader", this.doHide);
  },
  methods: {
    show() {
      bus.$emit("showLoader");
    },
    hide() {
      bus.$emit("hideLoader");
    },
    doShow() {
      if (window.loader === true) return;
      else {
        window.loader = true;
        // Mostra il loader dopo 0.5 secondi
        this.showLoaderTimeout = setTimeout(() => {
          this.loader.style.left = "0";
          this.loader.style.zIndex = "9999";
        }, 500);
        // Blocca il loader dopo 10 secondi
        this.hideLoaderTimeout = setTimeout(() => {
          this.doHide();
          // Prende più tempo del dovuto nascondo il loader e blocco tutto
          console.error("Il caricamento ha richiesto troppo tempo");
        }, 10000);
      }
    },
    doHide() {
      window.loader = false;
      // Devo eliminare il timeout o rimane appeso
      clearTimeout(this.showLoaderTimeout);
      clearTimeout(this.hideLoaderTimeout);
      this.loader.style.left = "-100%";
    },
  },
};
</script>

<style src="@/styles/components/_common/_loader.scss" lang="scss"></style>
