import { formatDateToTypeClient as f } from "@/utils/date-utils";
import { addDays } from "date-fns";

export default function () {
  return {
    ...this,
    uris: {
      ...this.uris,
      worklog: "/auth/worklog",
    },

    // WORKLOG

    fetchWorkLogs({ from, to }) {
      return this.call({
        method: this._method.GET,
        url: `${this.uris.worklog}`,
        params: { from: f(from), to: f(addDays(to, 1)) },
        needsAuth: true,
      });
    },

    fetchWorkLogsScheduled({ from, to }) {
      return this.call({
        method: this._method.GET,
        url: `${this.uris.worklog}/scheduled`,
        params: { from: f(from), to: f(addDays(to, 1)) },
        needsAuth: true,
      });
    },

    saveWorkLog(workLog, tipoRipetizione) {
      return this.call({
        method: this._method.POST,
        url: `${this.uris.worklog}`,
        params: { tipoRipetizione: tipoRipetizione },
        needsAuth: true,
        data: workLog,
      });
    },

    updateWorkLog(workLog, tipoRipetizione) {
      return this.call({
        method: this._method.PUT,
        url: `${this.uris.worklog}`,
        params: { tipoRipetizione: tipoRipetizione },
        needsAuth: true,
        data: workLog,
      });
    },

    deleteWorkLog(workLog) {
      return this.call({
        method: this._method.DELETE,
        url: `${this.uris.worklog}`,
        params: { idWorkLog: workLog.id },
        needsAuth: true,
      });
    },
  };
}
