import Vue from "vue";
import base from "./api-base";
import auth from "./auth";
import worklog from "./worklog";
import clienti from "./clienti";
import attivita from "./attivita";
import users from "./users";
import workers from "./workers";
import aziende from "./aziende";

// api definito come plugin di vue
// e come observable per poterle
// mettere sotto watch

export default {
  install(v) {
    v.prototype.$api = Vue.observable({
      ...base,
      errore: null,
      auth: auth.apply(base, []),
      worklog: worklog.apply(base, []),
      clienti: clienti.apply(base, []),
      attivita: attivita.apply(base, []),
      users: users.apply(base, []),
      workers: workers.apply(base, []),
      aziende: aziende.apply(base, []),
    });
  },
};
