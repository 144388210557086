import Vue from "vue";
import VueParticles from "vue-particles";
import VTooltip from "v-tooltip";
import App from "./App.vue";
import store from "./store";
import vuetify from "./plugins/vuetify";
import api from "./api";
import router from "./router";
import { Fragment } from "vue-frag";
import GlobalHelpers from "./utils/GlobalHelpers";
import VCurrencyField from "v-currency-field";
import { VTextField } from "vuetify/lib"; //Globally import VTextField
//https://vue-select.org/ -->free license
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
// import "@/registerServiceWorker";

Vue.component("FragmentMida", Fragment);
Vue.component("vue-select", vSelect);
Vue.use(VueParticles);
Vue.use(VTooltip);
Vue.use(api);
Vue.config.productionTip = false;
Vue.config.performance = true;

Vue.component("v-text-field", VTextField);
Vue.use(VCurrencyField, {
  locale: "it-IT",
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true,
});
Vue.use(GlobalHelpers);
export const bus = new Vue();

window.loader = false;
window.hasShownLoaderFirstTime = false;

Vue.prototype.toggleLoader = (show) =>
  bus.$emit(show ? "showLoader" : "hideLoader");

new Vue({
  store,
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
