export default function () {
  return {
    ...this,
    uris: {
      ...this.uris,
      businessnoauth: "/authentication/business",
      businessauth: "/auth/business",
    },

    saveBusiness(business) {
      // console.log(business);
      return this.call({
        method: this._method.POST,
        url: `${this.uris.businessnoauth}`,
        data: business,
        needsAuth: false,
      });
    },

    changeAdmin(admin) {
      // console.log(admin);
      return this.call({
        method: this._method.PUT,
        url: `${this.uris.businessauth}/admin`,
        data: admin,
        needsAuth: true,
      });
    },

    changeAbbonammento(subscription) {
      //console.log(subscription);
      return this.call({
        method: this._method.PUT,
        url: `${this.uris.businessauth}/subscription`,
        params: { subscriptionType: subscription },
        needsAuth: true,
      });
    },

    modBusiness(business) {
      // console.log({ business });
      return this.call({
        method: this._method.PUT,
        url: `${this.uris.businessauth}`,
        data: business,
        needsAuth: true,
      });
    },
  };
}
