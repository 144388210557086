export default function () {
  return {
    ...this,
    uris: {
      ...this.uris,
      worker: "/auth/worker",
    },

    fetchWorker() {
      return this.call({
        method: this._method.GET,
        url: `${this.uris.worker}`,
        needsAuth: true,
      });
    },

    saveWorker(worker) {
      //console.log("saveWorker", worker);
      return this.call({
        method: this._method.POST,
        url: `${this.uris.worker}`,
        needsAuth: true,
        data: worker,
      });
    },

    updateWorker(worker) {
      return this.call({
        method: this._method.PUT,
        url: `${this.uris.worker}`,
        needsAuth: true,
        data: worker,
      });
    },

    deleteWorker(idAzienda, worker) {
      return this.call({
        method: this._method.DELETE,
        url: `${this.uris.worker}`,
        params: { idWorker: worker.id },
        needsAuth: true,
      });
    },
  };
}
