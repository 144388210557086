/* eslint-disable */

import { enc, dec, pck, upck } from "./codec";
const tutils = {
  build: (strTok, s) => {
    if (!strTok || !s) return "";
    return tutils.encodeAscii(tutils._pck([...strTok.split("."), s]));
  },
  read: (str) => (str ? tutils._upck(tutils.decodeAscii(str)) : []),
  encode: enc,
  decode: dec,
  _pck: pck,
  _upck: upck,
  encodeAscii: function (str) {
    if (!str) return "";
    return Array.prototype.map
      .call(str, function (c) {
        let enc = tutils.encode(c, "");
        if (enc.length === 1) {
          enc = "0" + enc;
        }
        return enc;
      })
      .join("");
  },
  decodeAscii: function (str) {
    if (!str) return "";
    return str.replace(/[a-z0-9]{2}/gi, function (s) {
      return tutils.decode(s);
    });
  },
};

export default tutils;
