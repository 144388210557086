// import { Log, R } from '@mida4/web-app-utils'
/* eslint-disable */
import { StatusCodes } from "http-status-codes";
import { _copyObject } from "@/entities/funzioni-comuni";

const clientiModule = {
  namespaced: true,
  state: () => ({
    elenco: [],
    attivo: null,
    savingCustomer: false,
    errore: "",
  }),
  mutations: {
    setElenco(state, clienti) {
      // Log.debug('clienti/setElenco', clienti.length)
      state.elenco = clienti;
    },
    setErrore(state, err) {
      state.errore = err;
    },
    setAttivo(state, cli) {
      state.attivo = _copyObject(cli);
    },
    resetAttivo(state) {
      state.attivo = null;
    },
    aggiungi(state, cli) {
      state.elenco = [...state.elenco, cli].sort((a, b) =>
        a.surnameCompany.toLowerCase() > b.surnameCompany.toLowerCase() ? 1 : -1
      );
    },
    elimina(state, cli) {
      state.elenco = [...state.elenco].filter((c) => c.id !== cli.id);
    },
    aggiorna(state, cli) {
      state.elenco = [
        ...state.elenco.map((it) =>
          it.fiscalCode === cli.fiscalCode ? _copyObject(cli) : it
        ),
      ];
    },
    setSavingCustomer(state, val) {
      state.savingCustomer = val;
    },
  },
  actions: {
    async recuperaElenco({ commit, rootState }) {
      try {
        const res = await this._vm.$api.clienti.fetchClienti();
        if (res.status !== StatusCodes.OK) {
          throw new Error("Errore server durante il recupero dei clienti");
        }
        const clienti = [...res.data];
        commit(
          "setElenco",
          clienti.sort((a, b) =>
            a.surnameCompany.toLowerCase() > b.surnameCompany.toLowerCase()
              ? 1
              : -1
          )
        ); // sort ?
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async salvaCliente({ commit, state, dispatch, rootState }, cli) {
      commit("setSavingCustomer", true);
      try {
        // console.log("salverei: ", {
        //   idAzienda: rootState.auth.aziendaAttiva.id,
        //   cliente: cli,
        // });
        let res;
        if (cli.id == -1) {
          res = await this._vm.$api.clienti.saveCliente(cli);
        } else {
          res = await this._vm.$api.clienti.updateCliente(cli);
        }
        if (res.status !== StatusCodes.OK) {
          throw new Error(
            "Errore server durante il salvataggio del cliente: " +
              res.response.data
          );
        }
        cli = res.data;
        let trovato = state.elenco.find((it) => it.id === cli.id);
        if (_.isNil(trovato)) {
          // Log.debug("clienti - aggiungo", cli);
          commit("aggiungi", cli);
        } else {
          // Log.debug("clienti - aggiorno", cli);
          commit("aggiorna", cli);
        }
      } catch (error) {
        // console.log("error", error.message);

        commit("setErrore", error.message);
        commit("setSavingCustomer", false);
        return false;
      }
      commit("setSavingCustomer", false);
      return true;
    },
    async eliminaCliente({ commit, rootState }, cli) {
      try {
        const res = await this._vm.$api.clienti.deleteCliente(cli);
        commit("elimina", cli);
      } catch (error) {
        // console.log("error", error.message);
        return false;
      }
      return true;
    },
    setAttivo({ commit }, cli) {
      commit("setAttivo", cli);
    },
    resetAttivo({ commit }) {
      commit("resetAttivo");
    },
  },
  getters: {
    getClienti: (state) => state.elenco,
    getAttivo: (state) => state.attivo,
  },
};
export default clientiModule;
