/* eslint-disable */

import jwt_decode from "jwt-decode";
import { isBefore } from "date-fns";
import { _l } from "@/entities/log";
import axios from "axios";
import store from "@/store";
import LoginSteps from "@/entities/login-steps";
import REGEXES from "@/entities/regexes";

const client = axios.create();
const vApi = () => store._vm.$api;

async function renewAccTokRespErrInterceptor(error) {
  //_l("interceptor", JSON.stringify(error, null, 2));
  if (error.response && REGEXES.TOK_EXP.test(error.response.data)) {
    throw _l("api-base:16", error);
  }
  //vApi().setErrore(error);

  let config = error.config;
  if (error.response && error.response.status > 0) {
    if (error.response.status === 401 && !(error.config?._retry ?? false)) {
      await store.dispatch("auth/refreshToken");
      config = {
        ...config,
        _retry: true,
        headers: {
          ...config.headers,
          Authorization: vApi().getAuthHeader(),
        },
      };
      // ritento
      return client(config);
    } else {
      // store.commit("auth/resetState");
      // location.href = location.pathname;
    }
  }

  return error;
}

client.interceptors.request.use(
  (request) => {
    // console.log("client.interceptors.request - request", request);
    return request;
  },
  (error) => {
    apiBase.errore = _l("client.interceptors.request - error", error.message);
    return error;
  }
);
client.interceptors.response.use((res) => res, renewAccTokRespErrInterceptor);

const apiBase = {
  // PRIVATE

  _options: {
    baseURL:
      process.env.NODE_ENV == "development"
        ? process.env.VUE_APP_TEST_URL
        : process.env.VUE_APP_BASE_URL,
    headers: {
      "Content-type": "application/json",
    },
    timeout: 10000,
    withCredentials: true,
    responseType: "json",
  },

  _authHeader: null,

  _method: {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    PATCH: "PATCH",
    DELETE: "DELETE",
  },

  _getUrl(uri) {
    let base =
      process.env.NODE_ENV == "development"
        ? process.env.VUE_APP_TEST_URL
        : process.env.VUE_APP_BASE_URL;
    return base + uri;
  },

  // PUBLIC

  setErrore(err) {
    this.errore = _l("api-base: setErrore", err);
  },

  getAuthHeader() {
    //_l("getAuthHeader", store.state.auth.token);
    return `Bearer ${store.state.auth.token}`;
  },

  async call({
    method,
    url,
    params,
    data,
    needsAuth = false,
    headers = {},
    responseType = "json",
  }) {
    //_l("api call:", `${method} ${url} ${params}`);
    store.dispatch("resetAxiosError");

    const opts = {
      ...this._options,
      url: this._getUrl(url),
      method,
      headers,
      params,
      data,
      responseType,
    };

    if (needsAuth) {
      let valid = true;
      let tokParts;
      // verifica token
      // se scaduto refresh
      try {
        // console.log("prima di recuperare il tok questo è lo store", store);
        tokParts = store.state.auth.token;
        // console.log("recuperato token", tokParts);
      } catch (error) {
        // console.log("errore recupero token", error.message);
        // se token non valido tento il refresh
        valid = false;
      }
      if (!valid || isBefore(new Date(tokParts.exp * 1000), new Date())) {
        // console.log("tento il refresh");
        await store.dispatch("auth/refreshToken");
      }
      opts.headers.Authorization = this.getAuthHeader();
    }
    let res;
    try {
      //console.log("opts", opts);
      res = await client(opts);
      if (res.response) {
        //_l("api-base:160", res.response?.data ?? "no data");
      }
    } catch (error) {
      store.dispatch("setAxiosError", _l(`api-base(${url}):168 catch`, error));
      // throw error;
    }
    return res;
  },

  uris: {
    datiIniziali: "/userApi/datiIniziali",
    login: "/authentication/login",
  },
  datiIniziali() {
    return this.call({
      needsAuth: true,
      method: this._method.GET,
      url: this.uris.datiIniziali,
    });
  },
};

export default apiBase;
