import _ from "lodash";
export default function () {
  return {
    ...this,
    uris: {
      ...this.uris,
      login: "/authentication/login",
      reset: "/authentication/resetRequest",
    },
    /////////////////////////
    login(credenziali) {
      return this.call({
        method: this._method.POST,
        url: this.uris.login,
        data: credenziali,
      });
    },
    listaAziende() {
      return this.call({
        method: this._method.GET,
        url: this.uris.login,
      });
    },
    selectAzienda(idBusiness) {
      // console.log("idBusiness", idBusiness);
      if (_.isNil(idBusiness)) {
        throw "parametro idBusiness mancante";
      }
      return this.call({
        method: this._method.PATCH,
        url: `${this.uris.login}/${idBusiness}`,
      });
    },
    logout() {
      return this.call({
        method: this._method.DELETE,
        url: this.uris.login,
      });
    },
    /////////////////////////
    reset(dati) {
      return this.call({
        method: this._method.PUT,
        params: {
          "g-recaptcha-response": dati.resetToken,
        },
        url: this.uris.reset,
        data: dati,
      });
    },
  };
}
