/* eslint-disable  */
import StatusCodes from "http-status-codes";

import _ from "lodash";

import AppDefaults from "@/entities/app-defaults";
import LoginSteps from "@/entities/login-steps";
import axiosErrors from "@/entities/axios-errors";
import jwt_decode from "jwt-decode";
import { _copyObject } from "@/entities/funzioni-comuni";

import { _l } from "@/entities/log";

const baseState = {
  loginStep: LoginSteps.CREDENZIALI,
  errore: null,
  token: null,
  credenziali: {},
  aziendaAttiva: null,
  users: [],
  aziende: [],
  isAdmin: false,
  forceReload: false,
};

const authModule = {
  namespaced: true,
  state: () => _copyObject(baseState),
  mutations: {
    resetState(state) {
      state.loginStep = LoginSteps.CREDENZIALI;
      state.token = null;
      state.aziendaAttiva = null;
      state.credenziali = {};
      state.aziende = [];
      state.isAdmin = false;
    },
    setAziendaAttiva(state, aziendaAttiva) {
      state.aziendaAttiva = aziendaAttiva;
    },
    setUsers(state, users) {
      state.users = users;
    },
    setAziende(state, aziende) {
      state.aziende = aziende;
    },
    addAzienda(state, azienda) {
      state.aziende.push(azienda);
    },
    refreshAziendaInElenco(state, azienda) {
      const index = state.aziende.findIndex((b) => b.id == azienda.id);
      state.aziende[index] = azienda;
    },
    setLoginStep(state, step) {
      state.loginStep = step;
    },
    setIsAdmin(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    initDatiLoginFromStorage(
      state,
      { token, activeBusiness, businesses, businessUsers }
    ) {
      // console.log("initDatiLoginFromStorage", {
      //   token,
      //   activeBusiness,
      //   businesses,
      //   businessUsers,
      // });
      if (
        [token, activeBusiness, businesses, businessUsers].some(
          (e) => _.isNil(e) || _.isEmpty(e)
        )
      ) {
        // throw Error("Initialization from local storage failed")
        // }
        // if (_.isNil(token)) {
        state.loginStep = LoginSteps.CREDENZIALI;
        state.token = null;
        state.aziendaAttiva = null;
        state.users = [];
      } else {
        state.loginStep = LoginSteps.LOGGED_IN;
        state.token = token;
        state.isAdmin = jwt_decode(state.token)?.isa ?? false;
        state.aziendaAttiva = activeBusiness;
        state.aziende = businesses;
        state.users = businessUsers;
        // let aziendaAttiva = state.aziendaAttiva;
        // if (aziendaAttiva === null && !_.isNil(appData.contratto)) {
        //   aziendaAttiva = appData.contratto.id;
        // }
        // state.aziendaAttiva = aziendaAttiva;
        // state.aziende = aziende;
      }
      // console.log("activeBusiness", activeBusiness);
    },
    setAccessToken(state, token) {
      if (_.isNil(token)) {
        state.loginStep = LoginSteps.CREDENZIALI;
        throw Error("Errore nell'impostare i dati di autenticazione");
      }
      // _l("setAccessTok", token);
      state.token = token;
    },
    setErrore(state, errore) {
      state.errore = errore;
    },
    setForceReload(state, value) {
      state.forceReload = value;
    },
  },
  actions: {
    async login({ commit, dispatch, getters }, credenziali) {
      this._vm.toggleLoader(true);
      try {
        const loginRes = await this._vm.$api.auth.login(credenziali);
        if (loginRes.status == StatusCodes.OK) {
          const aziende = (await this._vm.$api.auth.listaAziende()).data;
          //console.log(aziende);
          if (aziende.length) {
            commit("setAziende", aziende);
          }
          commit("setLoginStep", LoginSteps.SELEZIONE_AZIENDA);
        } else {
          // console.log(this._vm.$api);
          let message = AppDefaults.ERRORE_GENERICO;
          if (_.isNil(loginRes.response)) {
            message = axiosErrors[loginRes.code] || loginRes.message;
          } else {
            message = loginRes.response.data;
          }
          commit("setErrore", message);
        }
      } catch (error) {
        commit("setErrore", error.message);
      }
      this._vm.toggleLoader(false);
    },
    async selectAzienda({ commit, state }, idAzienda) {
      this._vm.toggleLoader(true);
      try {
        const response = await this._vm.$api.auth.selectAzienda(idAzienda);
        if (response.status == StatusCodes.OK) {
          const aziendaAttiva = state.aziende.filter(
            (az) => az.id == idAzienda
          );
          commit("setAziendaAttiva", aziendaAttiva[0]);
          commit("setAccessToken", response.data);
          commit("setLoginStep", LoginSteps.LOGGED_IN);

          const tokParts = jwt_decode(state.token);
          const isAdmin = tokParts.isa;
          const idUser = tokParts.uid;

          // console.log("selectAzienda tokparts", tokParts);

          const usersResponse = await this._vm.$api.users.fetchUtenti(
            idAzienda
          );
          if (usersResponse.status !== StatusCodes.OK) {
            if (_.isNil(usersResponse.response)) {
              message =
                axiosErrors[usersResponse.code] || usersResponse.message;
            } else {
              message = usersResponse.response.data.split(":")[0];
            }
            throw Error(message);
          }
          commit("setIsAdmin", isAdmin);
          commit(
            "setUsers",
            usersResponse.data.map((u) => ({ ...u, active: u.id === idUser }))
          );
          commit("setForceReload", true);
        } else {
          let message = AppDefaults.ERRORE_GENERICO;
          if (_.isNil(response.response)) {
            message = axiosErrors[response.code] || response.message;
          } else {
            message = response.response.data.split(":")[0];
          }
          throw Error(message);
        }
      } catch (error) {
        commit("setErrore", _l("store/auth selectAzienda", error.message));
      }
    },
    async refreshToken({ commit, state }) {
      try {
        const response = await this._vm.$api.auth.selectAzienda(
          state.aziendaAttiva.id
        );
        if (response.status === StatusCodes.OK) {
          await commit("setAccessToken", response.data);
        } else {
          _l("store/auth: refreshToken response != OK", response);
          commit("resetState");
        }
      } catch (error) {
        _l("store/auth: refreshToken", error);
      }
    },
    async refreshIsAdmin({ commit }) {
      // console.log("isAdmin", isAdmin);
      tokParts = jwt_decode(this.token);
      await commit("setIsAdmin", tokParts.isa);
    },
    async logout({ commit }) {
      this._vm.toggleLoader(true);
      await commit("resetState");
      // console.log("resetState OK?");
      await this._vm.$api.auth.logout();
      // console.log("logout ok");
      this._vm.toggleLoader(false);
    },
    async cambiaAdmin({ dispatch }, utente) {
      try {
        const response = await this._vm.$api.aziende.changeAdmin(utente);
        if (response.status === 204) {
          dispatch("logout"); //Effettuo il logout
        } else {
          _l("store/auth: cambiaAdmin response != OK", response);
        }
      } catch (error) {
        _l("store/auth: cambiaAdmin", error);
      }
    },

    async reset({ commit }, datiReset) {
      // console.log("reset", datiReset);
      try {
        const response = await this._vm.$api.auth.reset(datiReset);
        // console.log("response", response);
        if (response.status === StatusCodes.NO_CONTENT) {
          //await commit("setAccessToken", response.data);
          return true;
        } else {
          // _l("store/auth: refreshToken response != OK", response);
          // commit("resetState");
          return false;
        }
      } catch (error) {
        _l("store/auth: reset", error);
        return false;
      }
    },

    async impostaPassword({ commit }, { datiReset, recaptchaToken }) {
      // console.log("impostaPassword", datiReset);
      try {
        const response = await this._vm.$api.users.settaPasswordUtente(
          datiReset,
          recaptchaToken
        );
        // console.log("response", response);
        if (response.status === StatusCodes.NO_CONTENT) {
          //await commit("setAccessToken", response.data);
          return true;
        } else {
          throw new Error(
            "Errore server durante la registrazione della password: " +
              response.response.data
          );

          // _l("store/auth: refreshToken response != OK", response);
          // commit("resetState");
          return false;
        }
      } catch (error) {
        _l("store/auth: reset", error);
        commit("setErrore", error.message);
        return false;
      }
    },

    cambiaContratto({ commit }) {
      commit("setLoginStep", LoginSteps.SELEZIONE_AZIENDA);
    },
    setLoginStep({ commit }, step) {
      commit("setLoginStep", step);
    },
    async resetLoginStep({ commit }) {
      this._vm.toggleLoader(true);
      await commit("resetState");
      await this._vm.$api.auth.logout();
      window.location.href = window.location.pathname;
      this._vm.toggleLoader(false);
    },
    setAziendaAttiva({ commit }, aziendaAttiva) {
      commit("setAziendaAttiva", aziendaAttiva);
    },
    setErrore({ commit }, errore) {
      commit("setErrore", errore);
    },
    resetErrore({ commit }) {
      commit("setErrore", null);
    },
    setAziende({ commit }, aziende) {
      commit("setAziende", aziende);
    },
    async refreshAziendaInElenco({ commit }, azienda) {
      commit("refreshAziendaInElenco", azienda);
      const aziende = (await this._vm.$api.auth.listaAziende()).data;
      //console.log(aziende);
      if (aziende.length) {
        commit("setAziende", aziende);
      }
    },
    addAzienda({ commit }, azienda) {
      commit("addAzienda", azienda);
    },
    setForceReload({ commit }, value) {
      commit("setForceReload", value);
    },
  },
  getters: {
    isUserLoggedIn: (state) => state.loginStep === LoginSteps.LOGGED_IN,
    activeUser: (state) => state.users.find((u) => u.active === true),
  },
};
export default authModule;
