export default function () {
  return {
    ...this,
    uris: {
      ...this.uris,
      clienti: "/auth/customer",
    },

    // ABILITAZIONI

    fetchClienti() {
      return this.call({
        method: this._method.GET,
        url: `${this.uris.clienti}`,
        needsAuth: true,
      });
    },

    saveCliente(cliente) {
      return this.call({
        method: this._method.POST,
        url: `${this.uris.clienti}`,
        needsAuth: true,
        data: cliente,
      });
    },

    updateCliente(cliente) {
      return this.call({
        method: this._method.PUT,
        url: `${this.uris.clienti}`,
        needsAuth: true,
        data: cliente,
      });
    },

    deleteCliente(cliente) {
      return this.call({
        method: this._method.DELETE,
        url: `${this.uris.clienti}`,
        params: { idCustomer: cliente.id },
        needsAuth: true,
      });
    },
  };
}
