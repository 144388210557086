/* eslint-disable */
import { StatusCodes } from "http-status-codes";
import { _copyObject } from "@/entities/funzioni-comuni";

const workersModule = {
  namespaced: true,
  state: () => ({
    elenco: [],
    elencoFiltrato: [],
    savingWorker: false,
    errore: "",
  }),

  mutations: {
    setElenco(state, workers) {
      // Log.debug('workers/setElenco', workers.length)
      state.elenco = [...workers];
      state.elencoFiltrato = [...workers];
      state.elencoFiltrato.unshift({ id: -1, nomeCognome: "Tutti" });
    },
    setErrore(state, err) {
      state.errore = err;
    },
    aggiungi(state, worker) {
      state.elenco = [...state.elenco, worker].sort((a, b) =>
        a.surname.toLowerCase() > b.surname.toLowerCase() ? 1 : -1
      );
    },
    elimina(state, worker) {
      state.elenco = [...state.elenco].filter((w) => w.id !== worker.id);
    },
    aggiorna(state, worker) {
      state.elenco = [
        ...state.elenco.map((it) =>
          it.email === worker.email ? _copyObject(worker) : it
        ),
      ];
    },
    setSavingWorker(state, val) {
      state.savingWorker = val;
    },
  },

  actions: {
    async recuperaElenco({ commit, rootState }) {
      try {
        const res = await this._vm.$api.workers.fetchWorker();
        if (res.status !== StatusCodes.OK) {
          throw new Error("Errore server durante il recupero dell'elenco");
        }
        const workers = [...res.data];
        //console.log(workers);
        commit("setElenco", workers);
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },

    async salvaWorker({ commit, state, dispatch, rootState }, worker) {
      commit("setSavingWorker", true);
      try {
        /*console.log("salverei: ", {
          workers: worker,
        });*/
        let res;
        res = await this._vm.$api.workers.saveWorker(worker);
        if (res.status !== StatusCodes.OK) {
          throw new Error(
            "Errore server durante il salvataggio " + res.response.data
          );
        }
        worker = res.data;
        let trovato = state.elenco.find((it) => it.id === worker.id);
        if (_.isNil(trovato)) {
          // Log.debug("workers - aggiungo", worker);
          commit("aggiungi", worker);
        } else {
          // Log.debug("workers - aggiorno", worker);
          commit("aggiorna", worker);
        }
      } catch (error) {
        // console.log("error", error.message);

        commit("setErrore", error.message);
        commit("setSavingWorker", false);
        return false;
      }
      commit("setSavingWorker", false);
      return true;
    },

    async eliminaWorker({ commit, rootState }, worker) {
      try {
        const res = await this._vm.$api.workers.deleteWorker(
          rootState.auth.aziendaAttiva.id,
          worker
        );
        if (res.status !== StatusCodes.OK) {
          throw new Error(
            "Errore server durante l'eliminazione dell'operatore"
          );
        }
        commit("elimina", worker);
      } catch (error) {
        // console.log("error", error.message);
        return false;
      }
      return true;
    },

    async salvaNuovoUtente({ commit }, { utente, recaptchaToken }) {
      try {
        let res = await this._vm.$api.users.registraUtente(
          utente,
          recaptchaToken
        );
        // console.log(res);
        if (res.status !== StatusCodes.NO_CONTENT) {
          throw new Error(
            "Errore server durante la registrazione: " + res.response.data
          );
        }
        utente = res.data;
      } catch (error) {
        commit("setErrore", error.message);
        return false;
      }
      return true;
    },
  },

  getters: {
    getWorkers: (state) => state.elenco,
  },
};
export default workersModule;
