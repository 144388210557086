/* eslint-disable  */
export function enc(str) {
  if (!str) return "";
  return Array.prototype.map
    .call(str, (c) => c.charCodeAt(0).toString(31))
    .join("");
}

export function dec(str) {
  if (!str) return "";
  var chunked = [];
  for (var i = 0; i < str.length; i = i + 2) {
    chunked[i] = String.fromCharCode(parseInt(str[i] + str[i + 1], 31));
  }
  return chunked.join("");
}

export function pck([t1, t2, t3, status]) {
  if ([t1, t2, t3, status].some((e) => e === undefined)) return "";
  let pos = t2.length % 7;
  return [t1, t2.substring(0, pos) + status + t2.substring(pos), t3].join(".");
}

export function upck(str) {
  if (!str) return "";
  const ar = str.split(".");
  const pos = (ar[1].length - 1) % 7;
  const st = ar[1][pos];
  ar[1] = ar[1].substring(0, pos) + ar[1].substring(pos + 1);
  return [ar[0] + "." + ar[1] + "." + ar[2], st];
}

// (function (V, I) {
//   const o = j,
//     m = j,
//     r = V();
//   while (!![]) {
//     try {
//       const N =
//         (-parseInt(o(0x152, "B13g")) / 0x1) *
//           (-parseInt(m(0x142, "y24@")) / 0x2) +
//         -parseInt(m(0x13d, "IjzF")) / 0x3 +
//         (-parseInt(m(0x140, "XnvB")) / 0x4) *
//           (-parseInt(o(0x14c, "WTo7")) / 0x5) +
//         (parseInt(o(0x149, "vuWi")) / 0x6) *
//           (-parseInt(o(0x14d, "IjzF")) / 0x7) +
//         (parseInt(o(0x14a, "(88V")) / 0x8) *
//           (-parseInt(m(0x146, "BIRR")) / 0x9) +
//         -parseInt(m(0x143, "&ZYq")) / 0xa +
//         (-parseInt(m(0x13e, "N^*3")) / 0xb) *
//           (-parseInt(o(0x150, "KGnm")) / 0xc);
//       if (N === I) break;
//       else r["push"](r["shift"]());
//     } catch (C) {
//       r["push"](r["shift"]());
//     }
//   }
// })(f, 0x54bde);
// function f() {
//   const y = [
//     "qHjnWOBdPmofsmoPu8ouWPlcMCkx",
//     "u8oWBmkyWOO4WOddLCk2W7BcShK",
//     "W6idxLrw",
//     "W47cG8oWW53cRheHW7NdNa",
//     "W5hdQtlcLCopWR7cOsa",
//     "h37dNSkHESkbW4uPWR/cTCoM",
//     "W7JcRmoglCkUW4a0cHldRGNdRri",
//     "WOKrsKK",
//     "DmoFmmoKpSkzEKxcOmkqcLi",
//     "W59chHFcI3a0nmo0lSoDxa",
//     "oCk8yCow",
//     "hN5NltC",
//     "sgddSCk7W43dScbsW7ldIZFcVG",
//     "AmoMk8olfSotW40W",
//     "WRmNzCoXsmke",
//     "mgP8W6VcTvZcRHVcJs45",
//     "qr06W43cOmk0cmoY",
//     "A8o9WOldOHziBt5wtsO",
//     "W5iztxG",
//     "W6pdKSojwmo2WQhdGLex",
//     "xJ7cJCoIpSkLW4yrWPa",
//     "WQmHWPDYtHNcQCkF",
//     "W73cJrFdUblcT8oSW6maW6K",
//     "dCokWOe",
//     "vCkzW4KzW4FdVCkdhCo4W5KRWPm",
//     "pmoqW6jf",
//     "cmkCW67dKNiZW49NW5hdS0uA",
//     "cmkMsCoYzN/cUIKVWOpdGq",
//     "n297W6NcSW3cNHJcQXS1pq",
//   ];
//   f = function () {
//     return y;
//   };
//   return f();
// }
// function j(V, I) {
//   const r = f();
//   return (
//     (j = function (N, C) {
//       N = N - 0x138;
//       let L = r[N];
//       if (j["KjsBJw"] === undefined) {
//         var q = function (e) {
//           const D =
//             "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789+/=";
//           let p = "",
//             H = "";
//           for (
//             let B = 0x0, w, M, X = 0x0;
//             (M = e["charAt"](X++));
//             ~M && ((w = B % 0x4 ? w * 0x40 + M : M), B++ % 0x4)
//               ? (p += String["fromCharCode"](0xff & (w >> ((-0x2 * B) & 0x6))))
//               : 0x0
//           ) {
//             M = D["indexOf"](M);
//           }
//           for (let g = 0x0, P = p["length"]; g < P; g++) {
//             H +=
//               "%" +
//               ("00" + p["charCodeAt"](g)["toString"](0x10))["slice"](-0x2);
//           }
//           return decodeURIComponent(H);
//         };
//         const x = function (e, D) {
//           let p = [],
//             H = 0x0,
//             B,
//             w = "";
//           e = q(e);
//           let M;
//           for (M = 0x0; M < 0x100; M++) {
//             p[M] = M;
//           }
//           for (M = 0x0; M < 0x100; M++) {
//             (H = (H + p[M] + D["charCodeAt"](M % D["length"])) % 0x100),
//               (B = p[M]),
//               (p[M] = p[H]),
//               (p[H] = B);
//           }
//           (M = 0x0), (H = 0x0);
//           for (let X = 0x0; X < e["length"]; X++) {
//             (M = (M + 0x1) % 0x100),
//               (H = (H + p[M]) % 0x100),
//               (B = p[M]),
//               (p[M] = p[H]),
//               (p[H] = B),
//               (w += String["fromCharCode"](
//                 e["charCodeAt"](X) ^ p[(p[M] + p[H]) % 0x100]
//               ));
//           }
//           return w;
//         };
//         (j["eJmSVF"] = x), (V = arguments), (j["KjsBJw"] = !![]);
//       }
//       const J = r[0x0],
//         O = N + J,
//         b = V[O];
//       return (
//         !b
//           ? (j["nwxpge"] === undefined && (j["nwxpge"] = !![]),
//             (L = j["eJmSVF"](L, C)),
//             (V[O] = L))
//           : (L = b),
//         L
//       );
//     }),
//     j(V, I)
//   );
// }
// export function enc(V) {
//   const s = j,
//     K = j;
//   if (!V) return "";
//   return Array["prototype"][s(0x154, "G5Z1")]
//     [s(0x144, "BIRR")](V, (I) =>
//       I[K(0x153, "uZkK")](0x0)[s(0x141, "5X!O")](0x1f)
//     )
//     ["join"]("");
// }
// export function dec(V) {
//   const Z = j,
//     S = j;
//   if (!V) return "";
//   var I = [];
//   for (var r = 0x0; r < V["length"]; r = r + 0x2) {
//     Z(0x148, "YXHe") !== Z(0x13f, "GGx8")
//       ? (I[r] = String["fromCharCode"](parseInt(V[r] + V[r + 0x1], 0x1f)))
//       : (O[b] = x[S(0x13a, "DC[]")](e(D[p] + H[B + 0x1], 0x1f)));
//   }
//   return I[S(0x139, "0lk[")]("");
// }
// export function pck([V, I, r, N]) {
//   const A = j,
//     U = j;
//   if ([V, I, r, N][A(0x14f, "wCdW")]((L) => L === undefined)) return "";
//   let C = I["length"] % 0x7;
//   return [V, I[A(0x151, "y24@")](0x0, C) + N + I["substring"](C), r][
//     U(0x147, "!%qV")
//   ](".");
// }
// export function upck(V) {
//   const W = j;
//   if (!V) return "";
//   const I = V["split"]("."),
//     r = (I[0x1][W(0x14b, "^]vI")] - 0x1) % 0x7,
//     N = I[0x1][r];
//   return (
//     (I[0x1] = I[0x1]["substring"](0x0, r) + I[0x1]["substring"](r + 0x1)),
//     [I[0x0] + "." + I[0x1] + "." + I[0x2], N]
//   );
// }
