/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import LZString from "lz-string";
import _ from "lodash";
import { _l } from "@/entities/log";

import REGEXES from "@/entities/regexes";
import router from "@/router";
// gestione errori
import { Alert, AlertType } from "@/entities/alert";
// auth
import authModule from "@/store/modules/auth";
import clientiModule from "@/store/modules/clienti";
import attivitaModule from "@/store/modules/attivita";
import worklogModule from "@/store/modules/worklog";
import workersModule from "@/store/modules/worker";
import businessModule from "@/store/modules/aziende";

import tutils from "@/store/modules/tutils";
import LoginSteps from "@/entities/login-steps.js";

Vue.use(Vuex);

// Vuex plugin - al momento non usato
const storeHelpers = (store) => {
  store.setSelectedContract = function (appData) {
    const idAziendaAttiva = _l("idAziendaAttiva", store.auth.aziendaAttiva);
    let selectedContract = appData.contrattiGestibili.find(
      (c) => c.id === idAziendaAttiva
    );
    if (_.isNil(selectedContract)) {
      selectedContract = appData.contrattiGestibili[0];
    }
    store.commit("setSelectedContract", selectedContract);
  };
};

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    s:
      _.isEmpty(state.auth.token) ||
      _.isNil(state.auth.token) ||
      !state.auth.loginStep === LoginSteps.LOGGED_IN
        ? ""
        : LZString.compressToUTF16(
            tutils.build(state.auth.token, state.auth.loginStep)
          ),
    ab:
      // _.isNil(state.auth.token) ||
      _.isNil(state.auth.aziendaAttiva) ||
      // _.isEmpty(state.auth.token) ||
      _.isEmpty(state.auth.aziendaAttiva)
        ? ""
        : LZString.compressToUTF16(JSON.stringify(state.auth.aziendaAttiva)),
    a:
      // _.isNil(state.auth.token) ||
      _.isNil(state.auth.aziende) ||
      // _.isEmpty(state.auth.token) ||
      _.isEmpty(state.auth.aziende)
        ? ""
        : LZString.compressToUTF16(JSON.stringify(state.auth.aziende)),
    u:
      _.isNil(state.auth.token) ||
      _.isNil(state.auth.users) ||
      _.isEmpty(state.auth.token) ||
      _.isEmpty(state.auth.users)
        ? ""
        : LZString.compressToUTF16(JSON.stringify(state.auth.users)),
  }),
  filter: (mutation) => {
    // Eventualmente vedere se serve limitare
    // il salvataggio alle mutazioni specifiche
    // rimosso perchè dava problemi

    // const ret = [
    //   "auth/setLoginStep",
    //   "auth/setUsers",
    //   "auth/setAccessToken",
    //   "auth/resetState",
    // ].includes(mutation.type);
    // return ret;
    return true;
  },
});

export default new Vuex.Store({
  state: {
    activeSection: null,
    axiosError: null,
    serverError: false,
    alert: null,
    updateAvailable: false,
    windowWidth: window.innerWidth,
  },
  modules: {
    auth: authModule,
    clienti: clientiModule,
    attivita: attivitaModule,
    worklog: worklogModule,
    worker: workersModule,
    aziende: businessModule,
  },
  mutations: {
    setAxiosError: (state, errore) => {
      let err = null;
      if (errore) {
        err = errore;
      }
      if (errore?.response?.data) {
        err = err + " - " + errore.response.data;
      }
      state.axiosError = err;
    },
    setServerError: (state, hasErrors) => (state.serverError = hasErrors),
    setAlert: (state, alert) => (state.alert = alert),
    setActiveSection: (state, activeSection) =>
      (state.activeSection = activeSection),
    setSelectedContract: (state, c) => (state.selectedContract = c),
    setUpdateAvailable: (state, isUpdateAvailable) =>
      (state.updateAvailable = isUpdateAvailable),
    setWindowWidth: (state, windowWidth) => (state.windowWidth = windowWidth),
  },
  actions: {
    // eslint-disable-next-line
    async initFromStorage({ commit, state, rootState }) {
      return new Promise((resolve, reject) => {
        if (state.auth.loginStep === LoginSteps.LOGGED_IN) {
          resolve(true);
        }
        try {
          let loggedIn = false;
          // console.log("initFromStorage state.s && state.a && state.u", {
          //   s: state.s,
          //   a: state.a,
          //   u: state.u,
          // });
          if (state.s && state.a && state.ab && state.u) {
            let token = null;
            let activeBusiness = null;
            if (_.isEmpty(rootState.auth.token)) {
              token = tutils.read(LZString.decompressFromUTF16(state.s))[0];
            } else {
              token = rootState.auth.token;
            }
            if (_.isEmpty(rootState.auth.aziendaAttiva)) {
              activeBusiness = JSON.parse(
                LZString.decompressFromUTF16(state.ab)
              );
            } else {
              activeBusiness = rootState.auth.aziendaAttiva;
            }
            const businesses = JSON.parse(
              LZString.decompressFromUTF16(state.a)
            );
            const businessUsers = JSON.parse(
              LZString.decompressFromUTF16(state.u)
            );
            // console.log("initFromStorage", { token, activeBusiness, businessUsers });
            commit("auth/initDatiLoginFromStorage", {
              token,
              activeBusiness,
              businesses,
              businessUsers,
            });
            // console.log("gState.aziendaAttiva", rootState.auth.aziendaAttiva);
            // console.log("gState.activeBusiness", activeBusiness);
            // if (_.isEmpty(rootState.auth.aziendaAttiva)) {
            // commit("auth/setAziendaAttiva", rootState.auth.aziendaAttiva);
            // }
            loggedIn = true;
            // console.log("initFromStorage action", { token, appData });
          }
          resolve(loggedIn);
        } catch (error) {
          // console.log("err", error.message);
          commit("auth/initDatiLoginFromStorage", {});

          reject(new Error(`Errore inizializzazione: ${error}`));
        }
      });
    },

    setAxiosError({ commit }, errore) {
      commit("setAxiosError", errore);
      if (errore) {
        _l(
          "store/index: setAxiosError errore.response.data",
          errore.response.data
        );
        if (REGEXES.TOK_EXP.test(errore.response.data)) {
          commit("auth/resetState");
          commit("auth/setLoginStep", LoginSteps.CREDENZIALI);
          router.replace("/login");
        }
      }
    },
    resetAxiosError({ commit }) {
      commit("setAxiosError", null);
    },
    setServerError({ commit }, hasErrors = true) {
      commit("setServerError", hasErrors);
    },
    setActiveSection({ commit }, activeSection) {
      commit("setActiveSection", activeSection);
    },
    setSelectedContract({ commit }, c) {
      commit("setSelectedContract", c);
    },
    setAlert({ commit, getters }, alertData) {
      try {
        commit("setAlert", null);
        if (alertData) {
          // const blockAlertOnLoginSection =
          //   getters.activeSection === "Login" && alertData.login !== true;

          // if (blockAlertOnLoginSection) {
          //   return false;
          // }
          const alert = new Alert(alertData);
          commit("setAlert", alert);
          return new Promise((resolve) => {
            if (alert.duration) {
              setTimeout(() => {
                resolve(commit("setAlert", null));
              }, alert.duration);
            } else {
              resolve(true);
            }
          });
        }
      } catch (error) {
        if (!_.isEmpty(alertData.msg)) {
          alert(alertData.msg);
        }
        commit("setAlert", null);
      }
    },
    setUpdateAvailable({ commit }, isUpdateAvailable = true) {
      commit("setUpdateAvailable", isUpdateAvailable);
    },
    setWindowWidth({ commit }, windowWidth) {
      commit("setWindowWidth", windowWidth);
    },
  },
  getters: {
    // OGNI GETTER RICEVE QUATTRO PARAMETRI
    // (state, getters, rootState, rootGetters)
    isMobile: (state) => state.windowWidth <= 600,
    isMobileDashboard: (state) => state.windowWidth <= 1330,
    isBrowserMobile: () =>
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Brave|Opera Mini/i.test(
        navigator.userAgent
      ),
  },
  plugins: [vuexLocal.plugin, storeHelpers],
});

// function isStatusSuccess(statusCode) {
//   return statusCode >= 200 && statusCode <= 299;
// }
// function createAndSetErrorAlert(dispatch, msg, duration, persistent) {
//   _l("store/index:333 createAndSetErrorAlert", msg);
//   const alert = {
//     show: true,
//     type: AlertType.ERROR,
//     msg,
//     duration,
//     persistent,
//   };
//   setAlert(dispatch, alert);
// }
// function setAlert(dispatch, alert) {
//   dispatch("setAlert", alert);
// }
