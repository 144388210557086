export default function () {
  return {
    ...this,
    uris: {
      ...this.uris,
      attivita: "/auth/activity",
    },

    fetchElenco() {
      return this.call({
        method: this._method.GET,
        url: `${this.uris.attivita}`,
        needsAuth: true,
      });
    },

    salvaAttivita(att) {
      return this.call({
        method: this._method.POST,
        url: `${this.uris.attivita}`,
        needsAuth: true,
        data: att,
      });
    },

    updateAttivita(att) {
      return this.call({
        method: this._method.PUT,
        url: `${this.uris.attivita}`,
        needsAuth: true,
        data: att,
      });
    },

    deleteAttivita(att) {
      return this.call({
        method: this._method.DELETE,
        url: `${this.uris.attivita}`,
        params: { idActivity: att.id },
        needsAuth: true,
      });
    },
  };
}
