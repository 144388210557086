/* eslint-disable */
import { StatusCodes } from "http-status-codes";
import { _copyObject } from "@/entities/funzioni-comuni";

const attivitaModule = {
  namespaced: true,
  state: () => ({
    elenco: [],
    attiva: null,
    savingActivity: false,
    errore: "",
  }),
  mutations: {
    setElenco(state, atts) {
      // Log.debug('clienti/setElenco', clienti.length)
      state.elenco = atts;
    },
    setErrore(state, err) {
      state.errore = err;
    },
    setAttiva(state, cli) {
      state.attiva = _copyObject(cli);
    },
    resetAttiva(state) {
      state.attiva = null;
    },
    aggiungi(state, att) {
      state.elenco = [...state.elenco, att];
    },
    elimina(state, att) {
      state.elenco = [...state.elenco].filter((a) => a.id !== att.id);
    },
    aggiorna(state, att) {
      state.elenco = [
        ...state.elenco.map((it) => (it.id === att.id ? _copyObject(att) : it)),
      ];
    },
    setSavingActivity(state, val) {
      // console.log("imposto savingAtt", val);
      state.savingActivity = val;
    },
  },
  actions: {
    async recuperaElenco({ commit, rootState }) {
      try {
        const res = await this._vm.$api.attivita.fetchElenco();
        if (res.status !== StatusCodes.OK) {
          throw new Error("Errore server durante il recupero dell'elenco");
        }
        const atts = [...res.data];
        commit("setElenco", atts);
        return true;
      } catch (error) {
        // console.error(error);
        return false;
      }
    },
    async salvaAttivita({ commit, state, dispatch, rootState }, att) {
      commit("setSavingActivity", true);
      try {
        // console.log("salverei: ", {
        //   idAzienda: rootState.auth.aziendaAttiva.id,
        //   cliente: cli,
        // });
        let res;
        if (att.id == -1) {
          res = await this._vm.$api.attivita.salvaAttivita(att);
        } else {
          res = await this._vm.$api.attivita.updateAttivita(att);
        }
        if (res.status !== StatusCodes.OK) {
          // console.log("RES", res);
          throw new Error(
            "Errore server durante il salvataggio dell'attività: " +
              res.response.data
          );
        }
        att = res.data;
        let trovata = state.elenco.find((it) => it.id === att.id);
        if (_.isNil(trovata)) {
          // Log.debug("clienti - aggiungo", cli);
          commit("aggiungi", att);
        } else {
          // Log.debug("clienti - aggiorno", cli);
          commit("aggiorna", att);
        }
      } catch (error) {
        // console.log("error", error.message);

        commit("setSavingActivity", false);
        commit("setErrore", error.message);
        return false;
      }
      commit("setSavingActivity", false);
      return true;
    },
    async eliminaAttivita({ commit, rootState }, att) {
      try {
        const res = await this._vm.$api.attivita.deleteAttivita(att);
        commit("elimina", att);
      } catch (error) {
        // console.log("error", error.message);
        return false;
      }
      return true;
    },
    setAttiva({ commit }, att) {
      commit("setAttiva", att);
    },
    resetAttiva({ commit }) {
      commit("resetAttiva");
    },
  },
  getters: {},
};

export default attivitaModule;
