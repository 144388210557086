export default function () {
  return {
    ...this,
    uris: {
      ...this.uris,
      utenti: "/auth/user",
      registrazione: "/register",
      registrazionePassword: "/authentication/changePassword",
    },

    fetchUtenti() {
      return this.call({
        method: this._method.GET,
        url: `${this.uris.utenti}`,
        needsAuth: true,
      });
    },

    registraUtente(utente, recaptchaToken) {
      // console.log("utente", { utente, recaptchaToken });
      return this.call({
        method: this._method.POST,
        params: {
          "g-recaptcha-response": recaptchaToken,
        },
        url: `${this.uris.registrazione}`,
        data: utente,
        needsAuth: false,
      });
    },

    settaPasswordUtente(utente, recaptchaToken) {
      // console.log("utente", { utente, recaptchaToken });
      return this.call({
        method: this._method.PUT,
        params: {
          "g-recaptcha-response": recaptchaToken,
        },
        url: `${this.uris.registrazionePassword}`,
        data: utente,
        needsAuth: false,
      });
    },
  };
}
