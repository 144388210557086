import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import AppDefaults from "../entities/app-defaults";
import { _err } from "@/entities/log";

const Menu = Object.freeze({
  NONE: 0,
  DESKTOP: 1,
  MOBILE: 2,
});

Vue.use(VueRouter);

/**
 * Si può utilizzare questa funzione per
 * ripulire lo store quando si cambia il contratto
 * loggato
 *
 * @param Array modules
 */

const routes = [
  {
    pathBase: "/login",
    path: "/",
    redirect: "/login",
    meta: {
      menu: Menu.NONE,
    },
  },
  {
    pathBase: "/login",
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginViewMida"),
    meta: {
      requiresAuth: false,
      menu: Menu.NONE,
      onlyAdmin: false,
    },
  },
  {
    pathBase: "/registrazione",
    path: "/registrazione",
    name: "Registrazione",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "registrazione" */ "../views/RegistrazioneViewMida"
      ),
    meta: {
      requiresAuth: false,
      menu: Menu.NONE,
      onlyAdmin: false,
    },
  },
  {
    pathBase: "/reset",
    path: "/reset",
    name: "Reset",
    component: () =>
      import(
        /* webpackChunkName: "reset" */ "../views/ImpostaPasswordViewMida"
      ),
    meta: {
      requiresAuth: false,
      menu: Menu.NONE,
      onlyAdmin: false,
    },
  },
  {
    pathBase: "/dashboard",
    path: "/dashboard",
    name: "Dashboard",
    props: true,
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/DashboardViewMida"),
    meta: {
      requiresAuth: true,
      icon: "mdi-view-dashboard",
      menu: Menu.MOBILE,
      onlyAdmin: false,
    },
  },
  {
    pathBase: "/clienti",
    path: "/clienti",
    name: "Gestione clienti",
    props: true,
    component: () =>
      import(/* webpackChunkName: "clienti" */ "../views/ClientiViewMida"),
    meta: {
      requiresAuth: true,
      icon: "mdi-account-supervisor-circle",
      menu: Menu.MOBILE,
      onlyAdmin: false,
      label: {
        user: "Clienti",
        admin: "Clienti",
      },
    },
  },
  {
    pathBase: "/attivita",
    path: "/attivita",
    name: "Gestione attività",
    props: true,
    component: () =>
      import(/* webpackChunkName: "attivita" */ "../views/AttivitaViewMida"),
    meta: {
      requiresAuth: true,
      icon: "mdi-order-bool-ascending",
      menu: Menu.MOBILE,
      onlyAdmin: false,
      label: {
        user: "Attività",
        admin: "Attività",
      },
    },
  },
  {
    pathBase: "/worker",
    path: "/worker",
    name: "Gestione operatori",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Workers" */ "../views/WorkersViewMida"),
    meta: {
      requiresAuth: true,
      icon: "mdi-office-building-outline",
      menu: Menu.MOBILE,
      onlyAdmin: true,
      label: {
        user: "Impostazioni",
        admin: "Azienda e Utenti",
      },
    },
  },
];

const router = new VueRouter({
  routes,
  menuBaseDesktopRoutes: () =>
    routes.filter((r) => r.meta.menu !== Menu.NONE && !r.meta.onlyAdmin),
  menuDesktopRoutes: () => routes.filter((r) => r.meta.menu !== Menu.NONE),
  menuMobileRoutes: () => routes.filter((r) => r.meta.menu === Menu.MOBILE),
});

router.beforeEach(async (to, _, next) => {
  // redirect home su route non riconosciuta
  if (to.matched.length === 0) {
    next("/");
  }
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  let authorized = !requiresAuth;
  if (requiresAuth) {
    try {
      //console.log("router.beforeEach");
      authorized = await store.dispatch("initFromStorage");
      //console.log("router.beforeEach", authorized);
    } catch (error) {
      _err("ERRORE da initFromStorage", error);
    }
  }

  if (!authorized) {
    store.dispatch("setActiveSection", null);
    next("/login");
  } else {
    store.dispatch("setActiveSection", to.name);
    next();
  }
});
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    const prefix = to.name ? to.name + " - " : "";
    document.title = prefix + AppDefaults.NOME_APP;

    if (from.name !== null && from.name !== to.name) {
      // console.log("router sparo l'evento");
      document.dispatchEvent(new CustomEvent("checkUpdates"));
    }
  });
});

export default router;
