/* eslint-disable */
import { StatusCodes } from "http-status-codes";

const businessModule = {
  namespaced: true,
  state: () => ({
    savingBusiness: false,
    aziendaAggiunta: {},
  }),

  mutations: {
    savingBusiness(state, val) {
      state.savingBusiness = val;
    },
    aggiungi(state, val) {
      state.aziendaAggiunta = val;
    },
  },

  actions: {
    async salvaBusiness({ commit, state, rootState }, business) {
      try {
        // console.log("salvaBusiness", business);
        const res = await this._vm.$api.aziende.saveBusiness(business);
        if (res.status !== StatusCodes.OK) {
          throw new Error("Errore server durante il salvataggio");
        }
        // console.log("res.data", res.data);
        commit("aggiungi", res.data);
      } catch (error) {
        // console.log("error", error.message);
        return false;
      }
      commit("savingBusiness", false);
      return true;
    },

    async modificaBusiness({ commit, state, rootState }, business) {
      try {
        const res = await this._vm.$api.aziende.modBusiness(business);
        if (res.status !== StatusCodes.OK) {
          throw new Error("Errore server durante il salvataggio");
        }
        //console.log("res.data", res.data);
      } catch (error) {
        // console.log("error", error.message);
        return false;
      }
      commit("savingBusiness", false);
      return true;
    },

    async upgradeSubscription({ commit }, subscription) {
      try {
        const res = await this._vm.$api.aziende.changeAbbonammento(
          subscription
        );
        if (res.status !== StatusCodes.OK) {
          throw new Error("Errore server durante il salvataggio");
        }
      } catch (error) {
        // console.log("error", error.message);
        return false;
      }
      commit("savingBusiness", false);
      return true;
    },
  },
};
export default businessModule;
